import { createApi } from '@reduxjs/toolkit/query/react';
import { closeModal } from 'store/modal/modalSlice';
import { setUser } from 'store/user/userSlice';
import { baseQueryWithReauth } from 'utils/services';

import {
  MessageQueryProps,
  TemplateMessage
} from '../patientMessageTemplates/patientMessageTemplates.types';
import type { UserProps } from '../user/user.types';

const tagTypes = [
  'Appointment',
  'Availability',
  'Benefits',
  'Billing',
  'Profile',
  'Article',
  'Patient',
  'Task',
  'AgingAlert',
  'AppointmentCategory',
  'AppointmentType',
  'Announcement',
  'BillingConfiguration',
  'Role',
  'Lookup',
  'AppointmentLengths',
  'GlobalAvailability',
  'Staff',
  'Staffs',
  'StaffCost',
  'StaffCosts',
  'PatientChannel',
  'FrontDeskChannel',
  'FrontDeskRequestType',
  'InternalNoteTemplate',
  'PatientMessageTemplate',
  'Notification',
  'Messages',
  'PhysicianRequestType',
  'RequestMessageTemplate',
  'SMS',
  'Prescriptions',
  'Trigger',
  'StaffNote',
  'Subscription',
  'Symptom',
  'Reviews',
  'Documents',
  'PatientAppointments',
  'LatestHealthMetrics',
  'HealthMetrics',
  'ChartHealthMetrics',
  'Settings',
  'PatientAppointments',
  'Event',
  'Tags',
  'PaymentTransactions',
  'Teams'
];

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: tagTypes,
  endpoints: (builder) => ({
    getMyProfile: builder.query<UserProps[], void>({
      query: () => `/my-profile`,
      providesTags: ['Profile'],
      transformResponse: (response: { data: UserProps[] }) => response.data,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data && data.length) {
          dispatch(setUser({ ...data[0], profileImage: data[0].profileImage || '' }));
          dispatch(closeModal());
        }
      }
    }),
    updateMyProfile: builder.mutation({
      query: ({ staff }: { staff: unknown }) => {
        return {
          url: `/my-profile`,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: staff
        };
      },
      invalidatesTags: ['Profile']
    }),

    updateProfileImage: builder.mutation({
      query: ({ profileImage }: { profileImage: File }) => {
        const formData = new FormData();
        formData.append('profileImage', profileImage);

        return {
          url: `/my-profile/profile-image`,
          method: 'POST',
          body: formData
        };
      }
    }),

    getMessage: builder.query<TemplateMessage, MessageQueryProps>({
      query: ({ id, type, patientId, doctorId }) => ({
        url: `/template-message/${id}/set-values`,
        params: { type, patientId, doctorId }
      }),
      transformResponse: (response: { data: TemplateMessage }) => response.data
    })
  })
});

export const {
  useUpdateMyProfileMutation,
  useLazyGetMyProfileQuery,
  useUpdateProfileImageMutation,
  useLazyGetMessageQuery
} = apiSlice;
