import { EDITOR_TOOLBAR_OPTIONS } from 'constants/editor';

import { useCallback, useRef, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import { Editor } from '@tinymce/tinymce-react/node_modules/tinymce';
import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import { MessageEditor } from 'components/common/Chat/MessageEditor/MessageEditor';
import { handleEditorFocus } from 'components/common/Chat/MessageEditor/messageEditor.settings';
import { StyledFormWrapper } from 'components/patient/Channel/Form/form.styled';
import Templates from 'components/patient/Channel/Form/Templates';
import { useTemplates } from 'contexts/TemplatesContext/TemplatesContext';
import useEditorToolbar from 'hooks/components/MessagesEditor/useEditorToolbar';
import { Controller, useForm } from 'react-hook-form';
import { useClickAway } from 'react-use';
import { useLazyGetMessageQuery } from 'store/api/apiSlice';
import { useSendSMSMutation } from 'store/sms/smsSlice';
import { parseMessage } from 'utils/common/messages/parseMessage';
import { sanitizeHTML, stripHTML } from 'utils/common/parseHTML';

import { SMSFormProps } from './smsForm.types';

const SMSForm: React.FC<SMSFormProps> = ({ patientId, doctorId, patientPhone }) => {
  const { control, watch, setValue } = useForm({
    defaultValues: {
      message: ''
    }
  });

  const {
    showTemplates,
    setShowTemplates,
    setSearchQuery,
    templates,
    selectedTemplateIndex,
    setSelectedTemplateIndex
  } = useTemplates();

  const isFormattingEnabled = !useFlag('disable-message-formatting');

  const smsText = watch('message', '');

  const [focus, setFocus] = useState(false);

  const formRef = useRef<HTMLDivElement | null>(null);
  const templatesRef = useRef<HTMLDivElement | null>(null);

  const [getMessageOfTemplate] = useLazyGetMessageQuery();
  const [sendSMS, { isLoading: isSendingSMS }] = useSendSMSMutation({
    fixedCacheKey: 'shared-send-sms'
  });

  const { showToolbar, toggleFormatText, setToolbar } = useEditorToolbar();

  const disabledSendSMS = !stripHTML(smsText)?.trim() || isSendingSMS;
  const disabledTemplates = showTemplates || smsText?.trim().length !== 0;

  const wrapperClasses = classNames(
    'relative mt-4 flex flex-col rounded-xl border border-gray-400 max-h-[260px] min-h-[90px]',
    {
      'ring-2 ring-blue-500': focus
    }
  );

  const handleTemplatesClick = () => {
    setShowTemplates(true);
    setValue('message', '/');
  };

  const handleSendMessage = useCallback(() => {
    // to not allow sending empty message which contains only HTML tags like '<p></p>'
    const allowedTags: string[] = []; // to remove all the HTML tags
    const onlyTextMessage = sanitizeHTML(smsText, allowedTags);

    if (!onlyTextMessage || onlyTextMessage.trim().length === 0) return;

    // but, when sending message, still, we should sanitize the message to remove any unwanted HTML tags
    // and preserve the default allowed tags
    const sanitizedHTML = sanitizeHTML(smsText);

    const body = {
      message: parseMessage(sanitizedHTML),
      phone: patientPhone
    };

    sendSMS({ patientId, body })
      .unwrap()
      .then(() => setValue('message', ''))
      .catch((error) => console.error(error));
  }, [patientId, patientPhone, sendSMS, setValue, smsText]);

  const onTemplateListClick = useCallback(
    (id: string, editor?: Editor) => {
      setShowTemplates(false);

      const params = {
        id: id,
        type: 'Message',
        patientId: patientId,
        doctorId: doctorId
      };

      getMessageOfTemplate(params)
        .unwrap()
        .then((response) => {
          const content = response?.message || '';

          if (editor) {
            editor?.setContent(content);
            handleEditorFocus(editor);
          } else {
            setValue('message', content);
          }
        })
        .catch((error) => console.error(error));
    },
    [doctorId, getMessageOfTemplate, patientId, setShowTemplates, setValue]
  );

  const handleKeyDown = (event: KeyboardEvent, editor?: Editor) => {
    const isUp = event.key === 'ArrowUp';
    const isDown = event.key === 'ArrowDown';
    const isEnter = event.key === 'Enter';

    // NOTE: to have numbered list and bulled list on Enter key press working,
    // we should prevent default behavior of Enter key press
    const shouldReactForEnterKeyPressed =
      isEnter && !event.shiftKey && showTemplates && templates[selectedTemplateIndex];
    const isPlainEnterKeyPress =
      isEnter && !event.shiftKey && !event.ctrlKey && !event.metaKey && !event.altKey;

    if (shouldReactForEnterKeyPressed) {
      // Handle template selection on Enter key press
      event.preventDefault();
      onTemplateListClick(templates[selectedTemplateIndex]._id, editor);
    } else if (isPlainEnterKeyPress) {
      // Handle message submission on Enter key press
      event.preventDefault();
      handleSendMessage();
    }

    // Hide templates
    if (event.key === 'Escape') {
      setShowTemplates(false);
    }

    // Open templates
    if (event.key === '/' && !showTemplates) {
      setShowTemplates(true);
    }

    // Scroll up or down through templates
    if (isUp && showTemplates) {
      event.preventDefault();
      selectedTemplateIndex === 0
        ? setSelectedTemplateIndex(templates.length - 1)
        : setSelectedTemplateIndex(selectedTemplateIndex - 1);
    }

    if (isDown && showTemplates) {
      event.preventDefault();
      selectedTemplateIndex === templates.length - 1
        ? setSelectedTemplateIndex(0)
        : setSelectedTemplateIndex(selectedTemplateIndex + 1);
    }
  };

  useClickAway(formRef, () => {
    setFocus(false);
  });

  useClickAway(templatesRef, () => {
    setShowTemplates(false);

    if (stripHTML(smsText) === '/') {
      setValue('message', '');
    }
  });

  return (
    <StyledFormWrapper>
      <div className={wrapperClasses} ref={formRef}>
        {showTemplates && (
          <Templates onTemplateListClick={onTemplateListClick} ref={templatesRef} />
        )}

        <Controller
          name="message"
          control={control}
          render={({ field: { onChange, ref } }) => {
            const handleEditorChange = (value: string, editor: Editor) => {
              const text = stripHTML(value);

              onChange(value);

              // Put cursor at the end of the user input
              if (text.trim() === '/') {
                editor.selection.select(editor.getBody(), true);
                editor.selection.collapse(false);
                editor.focus();
              }

              if (text.startsWith('/')) {
                setSearchQuery(text.replace('/', ''));
              } else if (!text.startsWith('/')) {
                setShowTemplates(false);
              }
            };

            return (
              <MessageEditor
                value={smsText}
                onEditorChange={handleEditorChange}
                placeholder="SMS to patient..."
                onKeyDown={handleKeyDown}
                onFocus={() => setFocus(true)}
                onInit={(_, editor) => {
                  setToolbar();
                  ref(editor);
                }}
                toolbarOptions={EDITOR_TOOLBAR_OPTIONS}
              />
            );
          }}
        />

        <div className="absolute bottom-0 w-full rounded-b-xl bg-white p-1">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1">
              <Common.Button
                dataTestId="reply_templates_btn"
                data-testid="reply_templates_button"
                onClick={handleTemplatesClick}
                preIcon="lightning"
                size="sm"
                color="white-alt"
                disabled={disabledTemplates}
              >
                Templates
              </Common.Button>
              {isFormattingEnabled && (
                <Common.Button
                  dataTestId="format_text_btn"
                  data-testid="format_text_button"
                  onClick={toggleFormatText}
                  color="white-alt"
                  className={`${showToolbar ? 'bg-white ring-1 ring-gray-200' : ''} items-end rounded-lg`}
                  size="sm"
                >
                  <Common.Icon name="format-text" className="size-4" />
                </Common.Button>
              )}
            </div>

            <Common.Button
              dataTestId="send_sms_btn"
              data-testid="send_sms_button"
              onClick={handleSendMessage}
              disabled={disabledSendSMS}
              size="sm"
              type="submit"
              color="green"
              className="rounded-lg px-3 py-1"
            >
              <Common.Icon name="send" className={'size-4'} />
            </Common.Button>
          </div>
        </div>
      </div>
    </StyledFormWrapper>
  );
};

export default SMSForm;
