import { FRONTEND_DATE_FORMAT } from 'constants/dateFormat';

import { useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { Editor } from '@tinymce/tinymce-react';
import DatePickerInput from 'components/common/DatePickerInput';
import { notifySuccess } from 'components/common/Toast/Toast';
import ControlledMultiSelect from 'components/forms/controlled/ControlledMultiSelect';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import PopupFooter from 'components/modals/components/PopupFooter';
import PopupHeader from 'components/modals/components/PopupHeader';
import dayjs from 'dayjs';
import { Role, RoleShortName } from 'enums/role';
import startCase from 'lodash/startCase';
import { Option } from 'models/form.types';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLazyGetUserTypesQuery } from 'store/lookup/lookupSlice';
import { closeModal } from 'store/modal/modalSlice';
import { AnnouncementType } from 'store/staffAnnouncements/staffAnnouncements.types';
import {
  useCreateAnnouncementMutation,
  useDeleteAnnouncementMutation,
  useEditAnnouncementMutation,
  useGetAnnouncementByIdQuery
} from 'store/staffAnnouncements/staffAnnouncementsSlice';
import { validation } from 'utils/helpers';
import { ANY_CHARACTER_REGEXP } from 'utils/regExp';

import FormData, { AnnouncementEditorProps } from './AnnouncementEditor.types';

const BANNER_TYPE_OPTIONS = [
  { label: 'Banner', value: AnnouncementType.Banner },
  { label: 'Modal - Standard', value: AnnouncementType.ModalStandard },
  { label: 'Modal - Required Reading', value: AnnouncementType.ModalRequiredReading }
];

const AnnouncementEditor: React.FC<AnnouncementEditorProps> = ({ id }) => {
  const dispatch = useDispatch();
  const [getUserTypes, { data: userTypes }] = useLazyGetUserTypesQuery();
  const [createAnnouncement, { isLoading: loadCreate }] = useCreateAnnouncementMutation();
  const { data: announcementData, isLoading: loadData } = useGetAnnouncementByIdQuery({
    id: id ?? ''
  });
  const [editAnnouncement, { isLoading }] = useEditAnnouncementMutation();
  const [deleteAnnouncement, { isLoading: loadDelete }] = useDeleteAnnouncementMutation();
  const [startDateSelected, setStartDateSelected] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [hasButton, setHasButton] = useState(false);

  const [userTypeOptions, setUserTypeOptions] = useState<Option[]>([]);

  const { handleSubmit, control, formState, reset, getValues, watch, setValue } = useForm<FormData>(
    {
      reValidateMode: 'onChange'
    }
  );

  const onSuccess = (message: string) => {
    notifySuccess(message);
    dispatch(closeModal());
  };

  const announcementType = watch('announcementType');
  const useEditor = announcementType && announcementType.value !== AnnouncementType.Banner;

  const onSubmitForm = (formData: FormData) => {
    const body = {
      bannerType: formData.announcementType.value,
      name: formData.title,
      data: {
        body: formData.body,
        ...(hasButton && {
          button: {
            label: formData.buttonLabel,
            linkTo: formData.buttonLinkTo
          }
        })
      },
      audience: formData.audience?.map((type) => {
        if (type.value === RoleShortName.Admin) {
          return {
            name: Role.AD,
            shortCode: RoleShortName.Admin
          };
        }
        const selectedUserType = userTypes?.find((userType) => userType.shortCode === type.value);
        return {
          name: selectedUserType?.name,
          shortCode: selectedUserType?.shortCode,
          displayName: selectedUserType?.displayName
        };
      }),
      startAt: new Date(formData.startDate),
      expiresAt: new Date(formData.endDate),
      status: formData.status?.value
    };

    if (id) {
      editAnnouncement({ id, body: body })
        .unwrap()
        .then((data) => onSuccess(data.message));
    } else {
      createAnnouncement({ body })
        .unwrap()
        .then((data) => onSuccess(data.message));
    }
  };

  const onDelete = () => {
    id &&
      deleteAnnouncement({ id })
        .unwrap()
        .then((data) => onSuccess(data.message));
  };

  useEffect(() => {
    if (userTypes) {
      const newUserTypes = [
        { label: Role.AD, value: 'AD' },
        ...userTypes.map((type) => ({ label: type.name, value: type.shortCode }))
      ];
      setUserTypeOptions(newUserTypes);
    }
  }, [userTypes]);

  useEffect(() => {
    getUserTypes();
  }, [getUserTypes]);

  useEffect(() => {
    if (id && announcementData) {
      const audience = announcementData?.audience?.map((item) => ({
        value: item.shortCode,
        label: item.name
      }));

      const announcementType = BANNER_TYPE_OPTIONS.find(
        (item) => item.value === announcementData.bannerType
      );

      if (announcementData?.data?.button?.label) {
        setHasButton(true);
      }

      reset({
        announcementType: announcementType,
        title: announcementData.name,
        body: announcementData.data?.body,
        audience: audience,
        ...(announcementData?.data?.button?.label && {
          buttonLabel: announcementData.data.button.label,
          buttonLinkTo: announcementData.data.button.linkTo
        }),
        startDate: dayjs(announcementData.startAt).format(FRONTEND_DATE_FORMAT),
        endDate: dayjs(announcementData.expiresAt).format(FRONTEND_DATE_FORMAT),
        status: announcementData.status
          ? {
              value: announcementData.status,
              label: startCase(announcementData.status)
            }
          : null // TODO: fix status for edit
      });
    }
  }, [announcementData, reset]);

  return (
    <div className="p-8">
      <PopupHeader
        title={id ? 'Edit announcement' : 'New announcement'}
        id={id}
        subtitle={id ? 'Modify an announcement' : 'Create a new announcement'}
      />

      <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <ControlledSelect
            control={control}
            labelDirection="row"
            options={BANNER_TYPE_OPTIONS}
            placeholder="Select..."
            label="Type"
            rules={validation('Task type')}
            name="announcementType"
          />
          <InputField
            name="title"
            label="Title"
            placeholder="Enter here..."
            control={control}
            labelDirection="row"
            type="text"
            errors={formState.errors.title}
            helper={formState.errors.title?.message}
            rules={validation('Title', ANY_CHARACTER_REGEXP)}
          />
          <div className="relative flex w-full flex-row items-baseline gap-1">
            <label className="min-w-[120px] text-sm font-semibold text-gray-700">Description</label>
            <div className="w-full">
              <div className="relative w-full">
                {useEditor ? (
                  <Controller
                    control={control}
                    name="body"
                    render={({ field: { onChange } }) => (
                      <Editor
                        initialValue={
                          announcementData?.data?.body ? announcementData?.data?.body : ''
                        }
                        init={{
                          apiKey: import.meta.env.VITE_TINYMCE_API_KEY,
                          height: 250,
                          width: '100%',
                          plugins: 'image link tinydrive',
                          tinydrive_token_provider: 'URL_TO_YOUR_TOKEN_PROVIDER',
                          toolbar: 'undo redo | bold italic | //image link',
                          image_advtab: true,
                          menubar: false,
                          branding: false,
                          content_style:
                            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          browser_spellcheck: true,
                          placeholder: 'Enter message here'
                        }}
                        onEditorChange={onChange}
                      />
                    )}
                  />
                ) : (
                  <InputField
                    name="body"
                    placeholder="Enter message..."
                    control={control}
                    labelDirection="row"
                    type="text"
                    errors={formState.errors.body}
                    helper={formState.errors.body?.message}
                  />
                )}
              </div>
            </div>
          </div>
          <ControlledMultiSelect
            control={control}
            label="Send to"
            name="audience"
            placeholder="Select audiences..."
            options={userTypeOptions}
            labelDirection="row"
            errors={formState.errors.audience}
            rules={validation('Audience')}
          />
          <div className="relative flex w-full flex-row items-baseline gap-1">
            <label className="min-w-[120px] text-sm font-semibold text-gray-700">Button</label>
            <div className="w-full">
              <div className="relative w-full">
                <Common.Toggle
                  type="button"
                  color="blue"
                  size="md"
                  checked={hasButton}
                  onClick={() => setHasButton((prev) => !prev)}
                />
              </div>
            </div>
          </div>
          {hasButton && (
            <>
              <InputField
                name="buttonLabel"
                label="Button label"
                placeholder="Click here"
                control={control}
                labelDirection="row"
                type="text"
                errors={formState.errors.buttonLabel}
                helper={formState.errors.buttonLabel?.message}
              />
              <InputField
                name="buttonLinkTo"
                label="Link to"
                placeholder="/same-page"
                control={control}
                labelDirection="row"
                type="text"
                errors={formState.errors.buttonLinkTo}
                helper={formState.errors.buttonLinkTo?.message}
              />
            </>
          )}
        </div>
        <div className="border-t border-gray-200"></div>
        <div className="flex flex-col gap-4">
          <div className="flex">
            <div className="w-full pr-2">
              <DatePickerInput
                placeholder="Select a date range"
                label="Start date"
                labelDirection="row"
                inputValue={getValues('startDate') ?? ''}
                setInputValue={(value) => setValue('startDate', value)}
                selectedDate={startDateSelected}
                setSelectedDate={setStartDateSelected}
                startAllowedDate={new Date()}
                wrapperClasses="w-full"
                size="sm"
              />
            </div>
            <div className="w-full pl-2">
              <DatePickerInput
                placeholder="Select a date range"
                label="End date"
                labelDirection="row"
                inputValue={getValues('endDate') ?? ''}
                setInputValue={(value) => setValue('endDate', value)}
                selectedDate={endDate}
                setSelectedDate={setEndDate}
                startAllowedDate={new Date(getValues('startDate'))}
                wrapperClasses="w-full"
                size="sm"
              />
            </div>
          </div>
          <ControlledSelect
            control={control}
            labelDirection="row"
            options={[
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' },
              { label: 'Draft', value: 'draft' },
              { label: 'Deleted', value: 'deleted' }
            ]}
            placeholder="Select status..."
            label="Status"
            rules={validation('Status')}
            name="status"
          />
        </div>
        <PopupFooter
          hiddenDeleteButton={!id}
          onRemove={onDelete}
          disabled={loadData || isLoading || loadCreate || loadDelete}
          {...(!id && { saveButtonText: 'Create and launch' })}
        />
      </form>
    </div>
  );
};

export default AnnouncementEditor;
