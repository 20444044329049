//TODO: Remove unused params
export interface StaffAnnouncementProps {
  _id: string;
  name: string;
  bannerType: AnnouncementType;
  author: AnnouncementAuthor;
  audience: AnnouncementAudience[];
  // audience: string[];
  status: AnnouncementStatus;
  // draft: boolean;
  data: BannerData;
  startAt: Date;
  expiresAt: Date;
  createdAt: Date;
  modifiedBy: string;
  createdBy: string;
  modifiedAt: Date;
  deletedAt?: Date;
}

export interface AnnouncementAuthor {
  userId: string;
  name: string;
}

export interface AnnouncementAudience {
  name: string;
  shortCode: string;
  displayName?: string;
}

export enum AnnouncementStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DELETED = 'deleted',
  DRAFT = 'draft'
}

export interface Modal {
  title: string;
  body: string;
  button: {
    label: string;
    linkTo: string;
  };
}
type BannerData = Modal;

//TODO: Remove unused params
export interface StaffAnnouncementsQueryParams {
  limit?: number;
  pageNo?: number;
  sortField?: string;
  sortOrder?: string;
  searchKey?: string;
  name?: string;
  uniqueId?: string;
  status?: string;
  audience?: string[];
  isStaffView?: boolean;
}

export enum AnnouncementType {
  Banner = 'banner',
  ModalStandard = 'modal-standard',
  ModalRequiredReading = 'modal-required-reading'
}
