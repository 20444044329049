import { useState } from 'react';

import AgingAlert from 'components/modals/AgingAlert';
import CreateAnnouncement from 'components/modals/AnnouncementEditor';
import AppointmentType from 'components/modals/AppointmentTypes';
import Automation from 'components/modals/Automation';
import BillingConfigurationStandard from 'components/modals/BillingAndCostConfiguration/BillingConfigurationStandard';
import EditStaffCosts from 'components/modals/BillingAndCostConfiguration/EditStaffCosts';
import CreateTeam from 'components/modals/CreateTeam';
import InternalNoteTemplatesModal from 'components/modals/InternalNoteTemplates/InternalNoteTemplates';
import PatientMessageTemplatesModal from 'components/modals/PatientMessageTemplates/PatientMessageTemplates';
import PhysicianRequestTypes from 'components/modals/PhysicianRequestTypes';
import MessageTemplatesModal from 'components/modals/RequestMessageTemplates/RequestMessageTemplates';
import NewStaffMember from 'components/modals/StaffMember/StaffMember';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { PathName } from 'enums/pathName';
import { Role } from 'enums/role';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation, useMatch, useNavigate } from 'react-router';
import { openModal } from 'store/modal/modalSlice';
import { selectUser } from 'store/user/userSlice';

import { useAppSelector } from '../redux';

export function useHeaderParams(): {
  label: string;
  onClickNew?: () => void;
  showSelect?: boolean;
  showSelectProspects?: boolean;
  showBackBtn?: boolean;
  buttonTitle?: string;
  reportsDates?: { startDate: string; endDate: string };
  prevPath?: string;
  showAsyncHeader?: boolean;
} {
  const [prevPatientsParams, setPrevPatientsParams] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { userType } = useAppSelector(selectUser);

  const isManageRole = !!matchPath(`${PathName.ManageRole}/:id`, location.pathname);
  const isAvailability = !!matchPath(`${PathName.Availability}/:id`, location.pathname);
  const isTasks = !!matchPath(PathName.Tasks, location.pathname);
  const isActivityFeed = !!matchPath(PathName.ActivityFeed, location.pathname);
  const isCalendar = !!matchPath(PathName.Calendar, location.pathname);
  const isPatients = !!matchPath(PathName.Patients, location.pathname);
  const isProspect = !!matchPath(PathName.Prospect, location.pathname);
  const isPatient = !!matchPath(`${PathName.Patient}/:patientId`, location.pathname);
  const isArticles = !!matchPath(PathName.Articles, location.pathname);
  const isCreateArticle = !!matchPath(PathName.CreateArticle, location.pathname);
  const isUpdateArticle = !!matchPath(`${PathName.UpdateArticle}/:articleId`, location.pathname);
  const isArticle = !!matchPath(`${PathName.Articles}/:articleId`, location.pathname);
  const isAgingConfiguration = !!matchPath(PathName.AgingConfiguration, location.pathname);
  const isAppointmentTypes = !!matchPath(PathName.AppointmentTypes, location.pathname);
  const isFrontDeskRequestTypes = !!matchPath(PathName.FrontDeskRequestTypes, location.pathname);
  const isPatientMessageTemplates = !!matchPath(
    PathName.PatientMessageTemplates,
    location.pathname
  );
  const isRequestMessageTemplates = !!matchPath(
    PathName.RequestMessageTemplates,
    location.pathname
  );
  const isInternalNoteTemplates = !!matchPath(PathName.InternalNoteTemplates, location.pathname);
  const isStaffs = !!matchPath(PathName.Staffs, location.pathname);
  const isTeams = !!matchPath(PathName.Teams, location.pathname);
  const isManageRoles = !!matchPath(PathName.ManageRoles, location.pathname);
  const isInternalAutomations = !!matchPath(PathName.InternalAutomations, location.pathname);
  const isPatientAutomations = !!matchPath(PathName.PatientAutomations, location.pathname);
  const isBillingConfiguration = !!matchPath(PathName.BillingConfiguration, location.pathname);
  const isStaffCosts = !!matchPath(PathName.StaffCosts, location.pathname);
  const isOrders = !!matchPath(PathName.Orders, location.pathname);
  const isOrder = !!matchPath(`${PathName.Orders}/:orderId`, location.pathname);
  const isSettings = !!matchPath(PathName.SystemSettings, location.pathname);
  const isStaffAnnouncements = !!matchPath(PathName.StaffAnnouncements, location.pathname);
  const isReviews = !!matchPath(PathName.Reviews, location.pathname);
  const isCustomerFeedback = !!matchPath(PathName.CustomerFeedback, location.pathname);
  const isStaffFeedback = !!matchPath(PathName.StaffFeedback, location.pathname);
  const isAdmin = !!matchPath(PathName.Admin, location.pathname);

  const isADReports = !!useMatch(PathName.AdminReports);
  const isMAReports = !!useMatch(PathName.MedicalAssistantReports);
  const isPHReports = !!useMatch(PathName.PhysicianReports);
  const isReports = isADReports || isMAReports || isPHReports;

  if (isManageRole) {
    return { label: 'Manage roles', showBackBtn: true };
  }
  if (isTasks) {
    return { label: 'Tasks', showSelect: true, showAsyncHeader: true };
  }
  if (isActivityFeed) {
    return { label: 'Activity feed' };
  }
  if (isStaffAnnouncements) {
    return {
      label: 'Staff Announcements',
      showBackBtn: true,
      prevPath: PathName.Admin,
      ...(userType.name == Role.AD && {
        buttonTitle: 'Create announcement',
        onClickNew: () =>
          dispatch(openModal({ modalContent: <CreateAnnouncement />, size: 'xl', hideClose: true }))
      })
    };
  }
  if (isSettings) {
    return { label: 'System Settings', showBackBtn: true, prevPath: PathName.Admin };
  }
  if (isAdmin) {
    return { label: 'Admin' };
  }
  if (isCalendar) {
    return { label: 'Calendar' };
  }
  if (isReports) {
    const parsed = queryString.parse(location.search);
    const result = {
      reportsDates: { startDate: '', endDate: '' },
      label: ''
    };
    let startDate;
    let endDate;

    if (typeof parsed.startDate === 'string' && typeof parsed.endDate === 'string') {
      endDate = dayjs(new Date(parseInt(parsed.endDate))).format(DateFormat.MMM_DD);
      startDate = dayjs(new Date(parseInt(parsed.startDate))).format(DateFormat.MMM_DD);

      result.reportsDates = { startDate, endDate };
    }

    if (userType.name == Role.AD) {
      result.label = 'Platform utilization';
    } else {
      result.label = 'Reporting dashboard';
    }

    return result;
  }
  if (isProspect) {
    return { label: 'Patients', showSelectProspects: true };
  }
  if (isPatients) {
    const newUrl = location.pathname + location.search;
    if (newUrl !== prevPatientsParams) {
      setPrevPatientsParams(newUrl);
    }
    return { label: 'Patients', showSelectProspects: true };
  }
  if (isPatient) {
    return {
      label: 'Patients',
      showBackBtn: true,
      prevPath: prevPatientsParams || PathName.Patients
    };
  }
  if (isArticles) {
    return {
      label: 'Articles',
      ...(userType.name == Role.AD && {
        buttonTitle: 'Add  article',
        onClickNew: () => navigate(PathName.CreateArticle)
      })
    };
  }
  if (isCreateArticle) {
    return { label: 'Articles', showBackBtn: true };
  }
  if (isAvailability) {
    return {
      label: 'Appointment availability',
      showBackBtn: true,
      prevPath: `${PathName.Calendar}?active-tab=Availability`
    };
  }
  if (isUpdateArticle) {
    return { label: 'Articles', showBackBtn: true };
  }
  if (isArticle) {
    return { label: 'Articles', showBackBtn: true };
  }
  if (isAgingConfiguration) {
    return {
      label: 'Aging alerts',
      buttonTitle: 'Add alert',
      onClickNew: () =>
        dispatch(openModal({ modalContent: <AgingAlert />, size: 'lg', hideClose: true })),
      showBackBtn: true,
      prevPath: PathName.Admin
    };
  }
  if (isAppointmentTypes) {
    return {
      label: 'Appointment types',
      buttonTitle: 'Add type',
      onClickNew: () =>
        dispatch(openModal({ modalContent: <AppointmentType />, size: 'lg', hideClose: true })),
      showBackBtn: true,
      prevPath: PathName.Admin
    };
  }
  if (isFrontDeskRequestTypes) {
    return {
      label: 'Request types',
      buttonTitle: 'Add request',
      showBackBtn: true,
      onClickNew: () =>
        dispatch(
          openModal({ modalContent: <PhysicianRequestTypes />, size: 'lg', hideClose: true })
        ),
      prevPath: PathName.Admin
    };
  }
  if (isPatientMessageTemplates) {
    return {
      label: 'Patient message templates',
      buttonTitle: 'Add template',
      onClickNew: () =>
        dispatch(
          openModal({ modalContent: <PatientMessageTemplatesModal />, size: 'lg', hideClose: true })
        ),
      showBackBtn: true,
      prevPath: PathName.Admin
    };
  }
  if (isRequestMessageTemplates) {
    return {
      label: 'Request message templates',
      showBackBtn: true,
      buttonTitle: 'Add template',
      onClickNew: () => dispatch(openModal({ modalContent: <MessageTemplatesModal />, size: 'lg' }))
    };
  }
  if (isInternalNoteTemplates) {
    return {
      label: 'Internal note templates',
      showBackBtn: true,
      buttonTitle: 'Add template',
      onClickNew: () =>
        dispatch(
          openModal({ modalContent: <InternalNoteTemplatesModal />, size: 'lg', hideClose: true })
        ),
      prevPath: PathName.Admin
    };
  }
  if (isStaffs) {
    return {
      label: 'Staff',
      buttonTitle: 'Add staff',
      onClickNew: () =>
        dispatch(openModal({ modalContent: <NewStaffMember />, size: 'xl', hideClose: true })),
      showBackBtn: true,
      prevPath: PathName.Admin
    };
  }
  if (isManageRoles) {
    return {
      label: 'Manage Roles',
      buttonTitle: 'Add role',
      onClickNew: () => navigate(PathName.ManageRole + '/new'),
      showBackBtn: true,
      prevPath: PathName.Admin
    };
  }

  if (isTeams) {
    return {
      label: 'Teams',
      buttonTitle: 'Add team',
      onClickNew: () =>
        dispatch(openModal({ modalContent: <CreateTeam />, size: 'lg', hideCloseButton: true })),
      showBackBtn: true,
      prevPath: PathName.Admin
    };
  }
  if (isInternalAutomations) {
    return {
      label: 'Internal automations',
      buttonTitle: 'Add automation',
      onClickNew: () =>
        dispatch(openModal({ modalContent: <Automation />, size: 'lg', hideClose: true })),
      showBackBtn: true,
      prevPath: PathName.Admin
    };
  }
  if (isPatientAutomations) {
    return {
      label: 'Patient automations',
      buttonTitle: 'Add automation',
      onClickNew: () =>
        dispatch(
          openModal({ modalContent: <Automation isPatientTriggers />, size: 'lg', hideClose: true })
        ),
      showBackBtn: true,
      prevPath: PathName.Admin
    };
  }
  if (isBillingConfiguration) {
    return {
      label: 'Billing configuration',
      buttonTitle: 'Add billing',
      onClickNew: () =>
        dispatch(
          openModal({ modalContent: <BillingConfigurationStandard />, size: 'lg', hideClose: true })
        ),
      showBackBtn: true,
      prevPath: PathName.Admin
    };
  }
  if (isStaffCosts) {
    return {
      label: 'Staff costs configuration',
      buttonTitle: 'Add configuration',
      onClickNew: () =>
        dispatch(openModal({ modalContent: <EditStaffCosts />, size: 'lg', hideClose: true })),
      showBackBtn: true,
      prevPath: PathName.Admin
    };
  }
  if (isOrders) {
    return { label: 'Orders' };
  }
  if (isOrder) {
    return { label: 'Orders', showBackBtn: true };
  }
  if (isReviews) {
    return { label: 'Review moderation', showBackBtn: true, prevPath: PathName.Admin };
  }
  if (isCustomerFeedback) {
    return { label: 'Customer feedback', showBackBtn: true, prevPath: PathName.Admin };
  }
  if (isStaffFeedback) {
    return { label: 'Staff feedback', showBackBtn: true, prevPath: PathName.Admin };
  }
  return { label: '' };
}
