import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { Role } from 'enums/role';
import { stripHTML } from 'utils/common/parseHTML';

import EditCell from './EditCell';
import { AnnouncementsTableProps } from './staffAnnouncementsTable.types';
import Header from '../Header';

const columnHelper = createColumnHelper<AnnouncementsTableProps>();

export const columns = (role: string) => {
  const isAdmin = role == Role.AD;

  return [
    columnHelper.accessor('createdAt', {
      header: () => <Header text="Date created" sortField="createdAt" />,
      cell: (info) => <span>{dayjs(info.getValue()).format(DateFormat.MMM_DYYYY)}</span>
    }),
    columnHelper.accessor('name', {
      header: () => <Header text="Name" sortField="name" />,
      size: isAdmin ? 150 : 600,
      cell: (info) => <span className="font-bold">{info.getValue()}</span>
    }),
    columnHelper.accessor('data', {
      header: () => <Header text="Description" sortField="data" />,
      size: isAdmin ? 150 : 600,
      cell: (info) => {
        const body = stripHTML(info.getValue().body);
        const content = body.length > 60 ? body.substring(0, 60) + '...' : body;
        return <span>{content}</span>;
      }
    }),
    columnHelper.accessor('audience', {
      header: () => <Header text="Audience" sortField="audience" />,
      cell: (info) => {
        const audience = info.getValue().map((item) => item.shortCode);
        return <span>{audience.join(',')}</span>;
      }
    }),
    columnHelper.accessor('bannerType', {
      header: () => <Header text="Banner type" sortField="bannerType" />,
      cell: (info) => (
        <span id={info?.getValue()} className="capitalize">
          {info?.getValue()}
        </span>
      )
    }),
    columnHelper.accessor('_id', {
      header: () => <Header text="Unique ID" sortField="uniqueId" />
    }),
    columnHelper.accessor('status', {
      header: () => <Header text="Status" sortField="status" />,
      cell: (info) => (
        <span className={`capitalize ${info.getValue() === 'active' ? 'text-blue' : 'text-red'}`}>
          {info.getValue()}
        </span>
      )
    }),
    columnHelper.accessor('actions', {
      header: () => <span className="text-sm font-bold">Actions</span>,
      cell: (info) => <EditCell id={info.row.original._id} hiddenPencil={!isAdmin} />,
      enableSorting: false
    })
  ];
};
